
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch,
  onUnmounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm,
  ElMessageBox
} from 'element-plus';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';
import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';
import { RuleItem } from 'async-validator';
import { IntBoolean, uploadVideo } from '@/services/api';
import { useCreateSite, useUpdateSite, useSite } from '@/composables/api';
import { PartialSite, PreviewItems } from '@/interfaces/Site';
import useFormErrors from '@/utils/validate';
import { cloneDeep } from 'lodash';

const DEFAULT_FORM_VALUES: PartialSite = {
  logo: '',
  name: '',
  link: '',
  previewItems: [
    {
      cover: '',
      previewVideo: ''
    },
    {
      cover: '',
      previewVideo: ''
    },
    {
      cover: '',
      previewVideo: ''
    },
    {
      cover: '',
      previewVideo: ''
    },
    {
      cover: '',
      previewVideo: ''
    },
    {
      cover: '',
      previewVideo: ''
    }
  ]
};

const validatePreviewItems = (previewItems: PreviewItems[]) => {
  let completePreviewItemsCount = 0;
  let result = true;

  // check if info in previewItems are complete, if not, return false for the validation
  previewItems.forEach(item => {
    if (item.cover !== '' && item.previewVideo === '') {
      result = false;
      return;
    }
    if (item.cover === '' && item.previewVideo !== '') {
      result = false;
      return;
    }
    if (item.cover !== '' && item.previewVideo !== '') {
      completePreviewItemsCount++;
    }
  });

  if (completePreviewItemsCount < 2) {
    result = false;
  }

  return result;
};

const rules = {
  logo: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  name: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  link: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  description: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  previewItems: [
    {
      required: true,
      trigger: 'change',
      validator: (rule: RuleItem, PreviewItems: PreviewItems[]) => {
        if (!rule.required && !PreviewItems) {
          return true;
        }

        return validatePreviewItems(PreviewItems);
      },
      message: 'Please provide at least two pairs of preview items and the values must be in pairs'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const siteId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { formErrors, bindFormItemError } = useFormErrors();
    // [[{ name: ... }], [{ name: ... }]]
    const fileLists = ref([]);

    const { data, dataUpdatedAt } = useSite({ siteId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const { isLoading: isCreatedLoading, mutate: create } = useCreateSite();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateSite();

    const handleCoverCrop = ({ path }: UploadImageEvent, index) => {
      formValues.value.previewItems[index].cover = path;
    };

    const handleDeleteCover = (event, index) => {
      formValues.value.previewItems[index].cover = '';
    };

    const handleLogoCrop = ({ path }: UploadImageEvent) => {
      formValues.value.logo = path;
    };

    const handleExceed = (files) => {
      ElMessage.warning(
      `The limit on upload is ${files.length} file at a time. Please delete the current file first.`
      );
    };

    const handlePreviewVideoRemove = (index: number) => {
      formValues.value.previewItems[index].previewVideo = '';
    };

    const handlePreviewVideoChange = async({ raw }: UploadFile, index: number) => {
      if (raw.size < 5242880) {
        const { data } = await uploadVideo({ data: { video: raw } });

        formValues.value.previewItems[index].previewVideo = data.path;
      } else {
        ElMessageBox.confirm(
          'File above 5MB cannot be uploaded',
          'Warning',
          {
            confirmButtonText: 'OK',
            showCancelButton: false,
            type: 'warning'
          }
        );
        fileLists.value[index] = [];
      }
    };

    // to prevent sending empty object when submitting the form
    const removeEmptyPreviewItems = () => {
      formValues.value.previewItems = formValues.value.previewItems.filter(item => {
        return (item.cover !== '' || item.previewVideo !== '');
      });
    };

    // to render UI for users to upload covers and videos
    const resetPreviewItems = () => {
      const defaultPreviewItems = cloneDeep(DEFAULT_FORM_VALUES.previewItems);

      const formPreviewItems = [...formValues.value.previewItems, ...defaultPreviewItems];

      return formPreviewItems.slice(0, 6);
    };

    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
      formValues.value.previewItems = resetPreviewItems();
    });

    watch(() => formValues.value.previewItems, (previewItems) => {
      fileLists.value = previewItems.map(({ previewVideo }) => previewVideo
        ? [{ name: previewVideo }]
        : []);
    }, {
      deep: true
    });

    onUnmounted(() => {
      formValues.value.previewItems = [
        {
          cover: '',
          previewVideo: ''
        },
        {
          cover: '',
          previewVideo: ''
        },
        {
          cover: '',
          previewVideo: ''
        },
        {
          cover: '',
          previewVideo: ''
        },
        {
          cover: '',
          previewVideo: ''
        },
        {
          cover: '',
          previewVideo: ''
        }
      ];
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          removeEmptyPreviewItems();

          if (isEdit.value) {
            update({ siteId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.push({
                  name: 'list-sites'
                });
              },
              onError(error: any) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success({
                message: 'success!',
                type: 'success'
              });

              formValues.value = DEFAULT_FORM_VALUES;

              router.push({
                name: 'list-sites'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      formRef,
      IntBoolean,
      page,
      formValues,
      fileLists,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      formErrors,
      handleLogoCrop,
      handleCoverCrop,
      handlePreviewVideoChange,
      handlePreviewVideoRemove,
      bindFormItemError,
      handleDeleteCover,
      handleExceed,
      submitForm
    };
  }
});
